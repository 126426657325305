import { default as dashboardRTJW1S1OnmMeta } from "/home/forge/app.inaia.uk/pages/dashboard.vue?macro=true";
import { default as _91id_93af5QJuNfYWMeta } from "/home/forge/app.inaia.uk/pages/depot/addsavingplan/[id].vue?macro=true";
import { default as _91id_930nu3AnfBR6Meta } from "/home/forge/app.inaia.uk/pages/depot/detail/[id].vue?macro=true";
import { default as _91type_93PvmvYYYDsKMeta } from "/home/forge/app.inaia.uk/pages/depot/new/[type].vue?macro=true";
import { default as index6rlZmVXVfZMeta } from "/home/forge/app.inaia.uk/pages/inbox-message/index.vue?macro=true";
import { default as index3pXrB6Z18SMeta } from "/home/forge/app.inaia.uk/pages/index.vue?macro=true";
import { default as loginoojCoP1BXdMeta } from "/home/forge/app.inaia.uk/pages/login.vue?macro=true";
import { default as indexMySkabLD7yMeta } from "/home/forge/app.inaia.uk/pages/profile/index.vue?macro=true";
import { default as verifyk9WC9puIGkMeta } from "/home/forge/app.inaia.uk/pages/profile/verify.vue?macro=true";
import { default as registerb4XSeQjzDZMeta } from "/home/forge/app.inaia.uk/pages/register.vue?macro=true";
import { default as request_45passwordlENL1CYpeqMeta } from "/home/forge/app.inaia.uk/pages/request-password.vue?macro=true";
import { default as reset_45passwordEUzwTxxs6KMeta } from "/home/forge/app.inaia.uk/pages/reset-password.vue?macro=true";
import { default as reset_45pinMQcfdqgqlEMeta } from "/home/forge/app.inaia.uk/pages/reset-pin.vue?macro=true";
import { default as simulatorSNZaD0XieHMeta } from "/home/forge/app.inaia.uk/pages/simulator.vue?macro=true";
import { default as indexoi7YKemXLiMeta } from "/home/forge/app.inaia.uk/pages/support-tickets/index.vue?macro=true";
import { default as _91_91depotid_93_93FVu8kimYmWMeta } from "/home/forge/app.inaia.uk/pages/trading/buy/[[depotid]].vue?macro=true";
import { default as _91_91depotid_93_939ECQOIddwkMeta } from "/home/forge/app.inaia.uk/pages/trading/delivery/[[depotid]].vue?macro=true";
import { default as _91_91depotid_93_93QtA5akYQBXMeta } from "/home/forge/app.inaia.uk/pages/trading/sell/[[depotid]].vue?macro=true";
import { default as _91_91depotid_93_937EWRborpobMeta } from "/home/forge/app.inaia.uk/pages/trading/transfer/[[depotid]].vue?macro=true";
export default [
  {
    name: dashboardRTJW1S1OnmMeta?.name ?? "dashboard___de",
    path: dashboardRTJW1S1OnmMeta?.path ?? "/de/dashboard",
    meta: dashboardRTJW1S1OnmMeta || {},
    alias: dashboardRTJW1S1OnmMeta?.alias || [],
    redirect: dashboardRTJW1S1OnmMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardRTJW1S1OnmMeta?.name ?? "dashboard___en",
    path: dashboardRTJW1S1OnmMeta?.path ?? "/en/dashboard",
    meta: dashboardRTJW1S1OnmMeta || {},
    alias: dashboardRTJW1S1OnmMeta?.alias || [],
    redirect: dashboardRTJW1S1OnmMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardRTJW1S1OnmMeta?.name ?? "dashboard___fr",
    path: dashboardRTJW1S1OnmMeta?.path ?? "/fr/dashboard",
    meta: dashboardRTJW1S1OnmMeta || {},
    alias: dashboardRTJW1S1OnmMeta?.alias || [],
    redirect: dashboardRTJW1S1OnmMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_93af5QJuNfYWMeta?.name ?? "depot-addsavingplan-id___de",
    path: _91id_93af5QJuNfYWMeta?.path ?? "/de/depot/addsavingplan/:id()",
    meta: _91id_93af5QJuNfYWMeta || {},
    alias: _91id_93af5QJuNfYWMeta?.alias || [],
    redirect: _91id_93af5QJuNfYWMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/addsavingplan/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93af5QJuNfYWMeta?.name ?? "depot-addsavingplan-id___en",
    path: _91id_93af5QJuNfYWMeta?.path ?? "/en/depot/addsavingplan/:id()",
    meta: _91id_93af5QJuNfYWMeta || {},
    alias: _91id_93af5QJuNfYWMeta?.alias || [],
    redirect: _91id_93af5QJuNfYWMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/addsavingplan/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93af5QJuNfYWMeta?.name ?? "depot-addsavingplan-id___fr",
    path: _91id_93af5QJuNfYWMeta?.path ?? "/fr/depot/addsavingplan/:id()",
    meta: _91id_93af5QJuNfYWMeta || {},
    alias: _91id_93af5QJuNfYWMeta?.alias || [],
    redirect: _91id_93af5QJuNfYWMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/addsavingplan/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_930nu3AnfBR6Meta?.name ?? "depot-detail-id___de",
    path: _91id_930nu3AnfBR6Meta?.path ?? "/de/depot/detail/:id()",
    meta: _91id_930nu3AnfBR6Meta || {},
    alias: _91id_930nu3AnfBR6Meta?.alias || [],
    redirect: _91id_930nu3AnfBR6Meta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_930nu3AnfBR6Meta?.name ?? "depot-detail-id___en",
    path: _91id_930nu3AnfBR6Meta?.path ?? "/en/depot/detail/:id()",
    meta: _91id_930nu3AnfBR6Meta || {},
    alias: _91id_930nu3AnfBR6Meta?.alias || [],
    redirect: _91id_930nu3AnfBR6Meta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_930nu3AnfBR6Meta?.name ?? "depot-detail-id___fr",
    path: _91id_930nu3AnfBR6Meta?.path ?? "/fr/depot/detail/:id()",
    meta: _91id_930nu3AnfBR6Meta || {},
    alias: _91id_930nu3AnfBR6Meta?.alias || [],
    redirect: _91id_930nu3AnfBR6Meta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91type_93PvmvYYYDsKMeta?.name ?? "depot-new-type___de",
    path: _91type_93PvmvYYYDsKMeta?.path ?? "/de/depot/new/:type()",
    meta: _91type_93PvmvYYYDsKMeta || {},
    alias: _91type_93PvmvYYYDsKMeta?.alias || [],
    redirect: _91type_93PvmvYYYDsKMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/new/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93PvmvYYYDsKMeta?.name ?? "depot-new-type___en",
    path: _91type_93PvmvYYYDsKMeta?.path ?? "/en/depot/new/:type()",
    meta: _91type_93PvmvYYYDsKMeta || {},
    alias: _91type_93PvmvYYYDsKMeta?.alias || [],
    redirect: _91type_93PvmvYYYDsKMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/new/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93PvmvYYYDsKMeta?.name ?? "depot-new-type___fr",
    path: _91type_93PvmvYYYDsKMeta?.path ?? "/fr/depot/new/:type()",
    meta: _91type_93PvmvYYYDsKMeta || {},
    alias: _91type_93PvmvYYYDsKMeta?.alias || [],
    redirect: _91type_93PvmvYYYDsKMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/depot/new/[type].vue").then(m => m.default || m)
  },
  {
    name: index6rlZmVXVfZMeta?.name ?? "inbox-message___de",
    path: index6rlZmVXVfZMeta?.path ?? "/de/inbox-message",
    meta: index6rlZmVXVfZMeta || {},
    alias: index6rlZmVXVfZMeta?.alias || [],
    redirect: index6rlZmVXVfZMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/inbox-message/index.vue").then(m => m.default || m)
  },
  {
    name: index6rlZmVXVfZMeta?.name ?? "inbox-message___en",
    path: index6rlZmVXVfZMeta?.path ?? "/en/inbox-message",
    meta: index6rlZmVXVfZMeta || {},
    alias: index6rlZmVXVfZMeta?.alias || [],
    redirect: index6rlZmVXVfZMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/inbox-message/index.vue").then(m => m.default || m)
  },
  {
    name: index6rlZmVXVfZMeta?.name ?? "inbox-message___fr",
    path: index6rlZmVXVfZMeta?.path ?? "/fr/inbox-message",
    meta: index6rlZmVXVfZMeta || {},
    alias: index6rlZmVXVfZMeta?.alias || [],
    redirect: index6rlZmVXVfZMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/inbox-message/index.vue").then(m => m.default || m)
  },
  {
    name: index3pXrB6Z18SMeta?.name ?? "index___de",
    path: index3pXrB6Z18SMeta?.path ?? "/de",
    meta: index3pXrB6Z18SMeta || {},
    alias: index3pXrB6Z18SMeta?.alias || [],
    redirect: index3pXrB6Z18SMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3pXrB6Z18SMeta?.name ?? "index___en",
    path: index3pXrB6Z18SMeta?.path ?? "/en",
    meta: index3pXrB6Z18SMeta || {},
    alias: index3pXrB6Z18SMeta?.alias || [],
    redirect: index3pXrB6Z18SMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3pXrB6Z18SMeta?.name ?? "index___fr",
    path: index3pXrB6Z18SMeta?.path ?? "/fr",
    meta: index3pXrB6Z18SMeta || {},
    alias: index3pXrB6Z18SMeta?.alias || [],
    redirect: index3pXrB6Z18SMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginoojCoP1BXdMeta?.name ?? "login___de",
    path: loginoojCoP1BXdMeta?.path ?? "/de/login",
    meta: loginoojCoP1BXdMeta || {},
    alias: loginoojCoP1BXdMeta?.alias || [],
    redirect: loginoojCoP1BXdMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginoojCoP1BXdMeta?.name ?? "login___en",
    path: loginoojCoP1BXdMeta?.path ?? "/en/login",
    meta: loginoojCoP1BXdMeta || {},
    alias: loginoojCoP1BXdMeta?.alias || [],
    redirect: loginoojCoP1BXdMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginoojCoP1BXdMeta?.name ?? "login___fr",
    path: loginoojCoP1BXdMeta?.path ?? "/fr/login",
    meta: loginoojCoP1BXdMeta || {},
    alias: loginoojCoP1BXdMeta?.alias || [],
    redirect: loginoojCoP1BXdMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexMySkabLD7yMeta?.name ?? "profile___de",
    path: indexMySkabLD7yMeta?.path ?? "/de/profile",
    meta: indexMySkabLD7yMeta || {},
    alias: indexMySkabLD7yMeta?.alias || [],
    redirect: indexMySkabLD7yMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexMySkabLD7yMeta?.name ?? "profile___en",
    path: indexMySkabLD7yMeta?.path ?? "/en/profile",
    meta: indexMySkabLD7yMeta || {},
    alias: indexMySkabLD7yMeta?.alias || [],
    redirect: indexMySkabLD7yMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexMySkabLD7yMeta?.name ?? "profile___fr",
    path: indexMySkabLD7yMeta?.path ?? "/fr/profile",
    meta: indexMySkabLD7yMeta || {},
    alias: indexMySkabLD7yMeta?.alias || [],
    redirect: indexMySkabLD7yMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: verifyk9WC9puIGkMeta?.name ?? "profile-verify___de",
    path: verifyk9WC9puIGkMeta?.path ?? "/de/profile/verify",
    meta: verifyk9WC9puIGkMeta || {},
    alias: verifyk9WC9puIGkMeta?.alias || [],
    redirect: verifyk9WC9puIGkMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/profile/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyk9WC9puIGkMeta?.name ?? "profile-verify___en",
    path: verifyk9WC9puIGkMeta?.path ?? "/en/profile/verify",
    meta: verifyk9WC9puIGkMeta || {},
    alias: verifyk9WC9puIGkMeta?.alias || [],
    redirect: verifyk9WC9puIGkMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/profile/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyk9WC9puIGkMeta?.name ?? "profile-verify___fr",
    path: verifyk9WC9puIGkMeta?.path ?? "/fr/profile/verify",
    meta: verifyk9WC9puIGkMeta || {},
    alias: verifyk9WC9puIGkMeta?.alias || [],
    redirect: verifyk9WC9puIGkMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/profile/verify.vue").then(m => m.default || m)
  },
  {
    name: registerb4XSeQjzDZMeta?.name ?? "register___de",
    path: registerb4XSeQjzDZMeta?.path ?? "/de/register",
    meta: registerb4XSeQjzDZMeta || {},
    alias: registerb4XSeQjzDZMeta?.alias || [],
    redirect: registerb4XSeQjzDZMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerb4XSeQjzDZMeta?.name ?? "register___en",
    path: registerb4XSeQjzDZMeta?.path ?? "/en/register",
    meta: registerb4XSeQjzDZMeta || {},
    alias: registerb4XSeQjzDZMeta?.alias || [],
    redirect: registerb4XSeQjzDZMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerb4XSeQjzDZMeta?.name ?? "register___fr",
    path: registerb4XSeQjzDZMeta?.path ?? "/fr/register",
    meta: registerb4XSeQjzDZMeta || {},
    alias: registerb4XSeQjzDZMeta?.alias || [],
    redirect: registerb4XSeQjzDZMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/register.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordlENL1CYpeqMeta?.name ?? "request-password___de",
    path: request_45passwordlENL1CYpeqMeta?.path ?? "/de/request-password",
    meta: request_45passwordlENL1CYpeqMeta || {},
    alias: request_45passwordlENL1CYpeqMeta?.alias || [],
    redirect: request_45passwordlENL1CYpeqMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/request-password.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordlENL1CYpeqMeta?.name ?? "request-password___en",
    path: request_45passwordlENL1CYpeqMeta?.path ?? "/en/request-password",
    meta: request_45passwordlENL1CYpeqMeta || {},
    alias: request_45passwordlENL1CYpeqMeta?.alias || [],
    redirect: request_45passwordlENL1CYpeqMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/request-password.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordlENL1CYpeqMeta?.name ?? "request-password___fr",
    path: request_45passwordlENL1CYpeqMeta?.path ?? "/fr/request-password",
    meta: request_45passwordlENL1CYpeqMeta || {},
    alias: request_45passwordlENL1CYpeqMeta?.alias || [],
    redirect: request_45passwordlENL1CYpeqMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/request-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordEUzwTxxs6KMeta?.name ?? "reset-password___de",
    path: reset_45passwordEUzwTxxs6KMeta?.path ?? "/de/reset-password",
    meta: reset_45passwordEUzwTxxs6KMeta || {},
    alias: reset_45passwordEUzwTxxs6KMeta?.alias || [],
    redirect: reset_45passwordEUzwTxxs6KMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordEUzwTxxs6KMeta?.name ?? "reset-password___en",
    path: reset_45passwordEUzwTxxs6KMeta?.path ?? "/en/reset-password",
    meta: reset_45passwordEUzwTxxs6KMeta || {},
    alias: reset_45passwordEUzwTxxs6KMeta?.alias || [],
    redirect: reset_45passwordEUzwTxxs6KMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordEUzwTxxs6KMeta?.name ?? "reset-password___fr",
    path: reset_45passwordEUzwTxxs6KMeta?.path ?? "/fr/reset-password",
    meta: reset_45passwordEUzwTxxs6KMeta || {},
    alias: reset_45passwordEUzwTxxs6KMeta?.alias || [],
    redirect: reset_45passwordEUzwTxxs6KMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45pinMQcfdqgqlEMeta?.name ?? "reset-pin___de",
    path: reset_45pinMQcfdqgqlEMeta?.path ?? "/de/reset-pin",
    meta: reset_45pinMQcfdqgqlEMeta || {},
    alias: reset_45pinMQcfdqgqlEMeta?.alias || [],
    redirect: reset_45pinMQcfdqgqlEMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/reset-pin.vue").then(m => m.default || m)
  },
  {
    name: reset_45pinMQcfdqgqlEMeta?.name ?? "reset-pin___en",
    path: reset_45pinMQcfdqgqlEMeta?.path ?? "/en/reset-pin",
    meta: reset_45pinMQcfdqgqlEMeta || {},
    alias: reset_45pinMQcfdqgqlEMeta?.alias || [],
    redirect: reset_45pinMQcfdqgqlEMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/reset-pin.vue").then(m => m.default || m)
  },
  {
    name: reset_45pinMQcfdqgqlEMeta?.name ?? "reset-pin___fr",
    path: reset_45pinMQcfdqgqlEMeta?.path ?? "/fr/reset-pin",
    meta: reset_45pinMQcfdqgqlEMeta || {},
    alias: reset_45pinMQcfdqgqlEMeta?.alias || [],
    redirect: reset_45pinMQcfdqgqlEMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/reset-pin.vue").then(m => m.default || m)
  },
  {
    name: simulatorSNZaD0XieHMeta?.name ?? "simulator___de",
    path: simulatorSNZaD0XieHMeta?.path ?? "/de/simulator",
    meta: simulatorSNZaD0XieHMeta || {},
    alias: simulatorSNZaD0XieHMeta?.alias || [],
    redirect: simulatorSNZaD0XieHMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/simulator.vue").then(m => m.default || m)
  },
  {
    name: simulatorSNZaD0XieHMeta?.name ?? "simulator___en",
    path: simulatorSNZaD0XieHMeta?.path ?? "/en/simulator",
    meta: simulatorSNZaD0XieHMeta || {},
    alias: simulatorSNZaD0XieHMeta?.alias || [],
    redirect: simulatorSNZaD0XieHMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/simulator.vue").then(m => m.default || m)
  },
  {
    name: simulatorSNZaD0XieHMeta?.name ?? "simulator___fr",
    path: simulatorSNZaD0XieHMeta?.path ?? "/fr/simulator",
    meta: simulatorSNZaD0XieHMeta || {},
    alias: simulatorSNZaD0XieHMeta?.alias || [],
    redirect: simulatorSNZaD0XieHMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/simulator.vue").then(m => m.default || m)
  },
  {
    name: indexoi7YKemXLiMeta?.name ?? "support-tickets___de",
    path: indexoi7YKemXLiMeta?.path ?? "/de/support-tickets",
    meta: indexoi7YKemXLiMeta || {},
    alias: indexoi7YKemXLiMeta?.alias || [],
    redirect: indexoi7YKemXLiMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/support-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: indexoi7YKemXLiMeta?.name ?? "support-tickets___en",
    path: indexoi7YKemXLiMeta?.path ?? "/en/support-tickets",
    meta: indexoi7YKemXLiMeta || {},
    alias: indexoi7YKemXLiMeta?.alias || [],
    redirect: indexoi7YKemXLiMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/support-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: indexoi7YKemXLiMeta?.name ?? "support-tickets___fr",
    path: indexoi7YKemXLiMeta?.path ?? "/fr/support-tickets",
    meta: indexoi7YKemXLiMeta || {},
    alias: indexoi7YKemXLiMeta?.alias || [],
    redirect: indexoi7YKemXLiMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/support-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93FVu8kimYmWMeta?.name ?? "trading-buy-depotid___de",
    path: _91_91depotid_93_93FVu8kimYmWMeta?.path ?? "/de/trading/buy/:depotid?",
    meta: _91_91depotid_93_93FVu8kimYmWMeta || {},
    alias: _91_91depotid_93_93FVu8kimYmWMeta?.alias || [],
    redirect: _91_91depotid_93_93FVu8kimYmWMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/buy/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93FVu8kimYmWMeta?.name ?? "trading-buy-depotid___en",
    path: _91_91depotid_93_93FVu8kimYmWMeta?.path ?? "/en/trading/buy/:depotid?",
    meta: _91_91depotid_93_93FVu8kimYmWMeta || {},
    alias: _91_91depotid_93_93FVu8kimYmWMeta?.alias || [],
    redirect: _91_91depotid_93_93FVu8kimYmWMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/buy/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93FVu8kimYmWMeta?.name ?? "trading-buy-depotid___fr",
    path: _91_91depotid_93_93FVu8kimYmWMeta?.path ?? "/fr/trading/buy/:depotid?",
    meta: _91_91depotid_93_93FVu8kimYmWMeta || {},
    alias: _91_91depotid_93_93FVu8kimYmWMeta?.alias || [],
    redirect: _91_91depotid_93_93FVu8kimYmWMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/buy/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_939ECQOIddwkMeta?.name ?? "trading-delivery-depotid___de",
    path: _91_91depotid_93_939ECQOIddwkMeta?.path ?? "/de/trading/delivery/:depotid?",
    meta: _91_91depotid_93_939ECQOIddwkMeta || {},
    alias: _91_91depotid_93_939ECQOIddwkMeta?.alias || [],
    redirect: _91_91depotid_93_939ECQOIddwkMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/delivery/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_939ECQOIddwkMeta?.name ?? "trading-delivery-depotid___en",
    path: _91_91depotid_93_939ECQOIddwkMeta?.path ?? "/en/trading/delivery/:depotid?",
    meta: _91_91depotid_93_939ECQOIddwkMeta || {},
    alias: _91_91depotid_93_939ECQOIddwkMeta?.alias || [],
    redirect: _91_91depotid_93_939ECQOIddwkMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/delivery/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_939ECQOIddwkMeta?.name ?? "trading-delivery-depotid___fr",
    path: _91_91depotid_93_939ECQOIddwkMeta?.path ?? "/fr/trading/delivery/:depotid?",
    meta: _91_91depotid_93_939ECQOIddwkMeta || {},
    alias: _91_91depotid_93_939ECQOIddwkMeta?.alias || [],
    redirect: _91_91depotid_93_939ECQOIddwkMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/delivery/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93QtA5akYQBXMeta?.name ?? "trading-sell-depotid___de",
    path: _91_91depotid_93_93QtA5akYQBXMeta?.path ?? "/de/trading/sell/:depotid?",
    meta: _91_91depotid_93_93QtA5akYQBXMeta || {},
    alias: _91_91depotid_93_93QtA5akYQBXMeta?.alias || [],
    redirect: _91_91depotid_93_93QtA5akYQBXMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/sell/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93QtA5akYQBXMeta?.name ?? "trading-sell-depotid___en",
    path: _91_91depotid_93_93QtA5akYQBXMeta?.path ?? "/en/trading/sell/:depotid?",
    meta: _91_91depotid_93_93QtA5akYQBXMeta || {},
    alias: _91_91depotid_93_93QtA5akYQBXMeta?.alias || [],
    redirect: _91_91depotid_93_93QtA5akYQBXMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/sell/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93QtA5akYQBXMeta?.name ?? "trading-sell-depotid___fr",
    path: _91_91depotid_93_93QtA5akYQBXMeta?.path ?? "/fr/trading/sell/:depotid?",
    meta: _91_91depotid_93_93QtA5akYQBXMeta || {},
    alias: _91_91depotid_93_93QtA5akYQBXMeta?.alias || [],
    redirect: _91_91depotid_93_93QtA5akYQBXMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/sell/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_937EWRborpobMeta?.name ?? "trading-transfer-depotid___de",
    path: _91_91depotid_93_937EWRborpobMeta?.path ?? "/de/trading/transfer/:depotid?",
    meta: _91_91depotid_93_937EWRborpobMeta || {},
    alias: _91_91depotid_93_937EWRborpobMeta?.alias || [],
    redirect: _91_91depotid_93_937EWRborpobMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/transfer/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_937EWRborpobMeta?.name ?? "trading-transfer-depotid___en",
    path: _91_91depotid_93_937EWRborpobMeta?.path ?? "/en/trading/transfer/:depotid?",
    meta: _91_91depotid_93_937EWRborpobMeta || {},
    alias: _91_91depotid_93_937EWRborpobMeta?.alias || [],
    redirect: _91_91depotid_93_937EWRborpobMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/transfer/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_937EWRborpobMeta?.name ?? "trading-transfer-depotid___fr",
    path: _91_91depotid_93_937EWRborpobMeta?.path ?? "/fr/trading/transfer/:depotid?",
    meta: _91_91depotid_93_937EWRborpobMeta || {},
    alias: _91_91depotid_93_937EWRborpobMeta?.alias || [],
    redirect: _91_91depotid_93_937EWRborpobMeta?.redirect,
    component: () => import("/home/forge/app.inaia.uk/pages/trading/transfer/[[depotid]].vue").then(m => m.default || m)
  }
]